import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuard } from './user/auth-guard.service';

export const routes: Routes = [
    { path: '', loadChildren: () => import('./cv/cost-vantage.module').then(m => m.CostVantageModule) } 
   // , { path: '**', component: PageNotFoundComponent, pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, enableTracing: true })],
    exports: [RouterModule]
})

export class AppRoutingModule { }
