import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { CoreService } from '../core/core.service';
import { CustomError } from '../core/view-models/custom-error';
import { CVLoginModel } from '../cv/cv-login/models/cv-login-model';
import { AuthTokenModel } from './auth-token-model';

@Injectable()
export class AuthService {

    headers = new HttpHeaders();
    validateJwtTokenURL = '/api/CVUser/validateJwtToken';
    refreshJWTTokenURL = '/api/CVUser/refreshJwtToken';
    CostVantageLogOutURL = '/api/CVUser/costVantageLogout';

    authTokenModel: AuthTokenModel = new AuthTokenModel();

    constructor(private http: HttpClient, private coreService: CoreService) { }
    isLoggedIn(): boolean {
        return true;
    }

    validateJwtToken(authTokenModel: AuthTokenModel): Observable<AuthTokenModel | CustomError> {
        return this.http.post<AuthTokenModel | CustomError>(`${this.validateJwtTokenURL}`, authTokenModel, { headers: this.headers })
    }

    refreshJWTToken(): Observable<AuthTokenModel | CustomError | boolean> {
        var userData = localStorage.getItem("userModel");
        if (userData) {
            this.authTokenModel = JSON.parse(userData);

            var tokenModel = new AuthTokenModel();
            tokenModel.userName = this.authTokenModel.userName;
            tokenModel.userToken = this.authTokenModel.userToken;

            return this.http.post<AuthTokenModel | CustomError>(`${this.refreshJWTTokenURL}`, tokenModel, { headers: this.headers, withCredentials: true })
                .pipe();

        } else {
            return of(false);
        }

    }

    public getJwtToken(): string {

        var userData = localStorage.getItem("userModel");
        var userJwtToken = '';
        //let userData = window.localStorage.getItem("isUserLoggedIn");
        if (userData) {
            this.authTokenModel = JSON.parse(userData);
            userJwtToken = this.authTokenModel.userToken;
        }
        return userJwtToken;
    }

    public CostVantageLogOut(): Observable<JSON | CustomError> {
        return this.http.get<JSON | CustomError>(`${this.CostVantageLogOutURL}`)
            .pipe();
    }

}
