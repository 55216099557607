import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ErrorContent } from '../models/error-content';

@Component({
    selector: 'cvs-error-banner',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.css'],
})
export class ErrorComponent implements OnInit {
    @Input() errorContent: ErrorContent;

    constructor(private ref: ElementRef) { }

    ngOnInit(): void { }

    public triggerFocus(selector: string): void {
        let el: HTMLElement = document.getElementById(selector);

        setTimeout(() => {
            if (el) {
                if (el.localName == 'p-calendar') {
                    const name = el.id;
                    const calendar = <HTMLElement>el.firstElementChild.firstElementChild;
                    calendar.focus();
                } else {
                    el.focus();
                }
            }
        });
    }
}
