import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { LoggerService } from '../logger.service';
import { CoreService } from '../core.service';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'pp-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
    userName: string;
    isUnreadMessage: boolean;
    copyright: string;
    disclaimer: string;
    organizations;
    selectedOrgId;
    previousSelectedOrgId;
    errorMessage: string;
    hideMenu: boolean = false;

    private orgUrl = '/api/layout/organization';
    private pageValidityUrl = '/api/layout/CheckPageValidity';


    constructor(private http: HttpClient,
        private router: Router,
        public coreService: CoreService) {
       

        // For Autologout
        this.check();
        this.initListener();
        this.initInterval();
        this.setLastActionTime(Date.now());
    }

    ngOnInit() {
        this.hideMenu = false;
        if (window.location.hash === '#/authenticate') {
            this.hideMenu = true;
        }
        this.coreService.getMarginals()
            .subscribe(
                (marginals: any) => {
                    this.userName = marginals.userName,
                        this.copyright = marginals.copyright.replace("#year#", (new Date()).getFullYear()),
                        this.disclaimer = marginals.disclaimer.replace(/[\n]/, ''),
                        this.isUnreadMessage = marginals.isUnreadMessage
                },
                error => {
                    this.errorMessage = <any>error;
                    location.replace('/Login');
                });

        this.coreService.getOrganizations()
            .subscribe(
                (data: any) => {
                    this.organizations = data;
                    if (this.organizations && this.organizations.length > 0) {
                        this.selectedOrgId = data[0].Id;
                        this.previousSelectedOrgId = this.selectedOrgId;
                    }
                },
                error => {
                    this.errorMessage = <any>error;
                });
    }



    // Auto Logout after user inactivity
    minutesUntilAutoLogout: number = 15 // in mins
    checkIntervel: number = 1000 // in ms
    lastActionTime: number;

    getLastActionTime() {
        return this.lastActionTime;
    }
    setLastActionTime(value) {
        this.lastActionTime = value;
    }

    initListener() {
        document.body.addEventListener('click', () => this.reset());
    }

    reset() {
        this.setLastActionTime(Date.now());
    }

    initInterval() {
        setInterval(() => {
            this.check();
        }, this.checkIntervel);
    }

    check() {
        const now = Date.now();
        const timeleft = this.getLastActionTime() + this.minutesUntilAutoLogout * 60 * 1000;
        const diff = timeleft - now;
        const isTimeout = diff < 0;

        if (isTimeout) {
            location.replace('/Login');
        }
    }

    // End Auto Logout after user inactivity

    updateOrganization() {
        let confirmMessage = 'If you have unsaved changes, click "Cancel" and save changes before attempting to navigate away from this page. Otherwise, click "OK".'
        if (!confirm(confirmMessage)) {
            this.selectedOrgId = this.previousSelectedOrgId;
        }
        this.previousSelectedOrgId = this.selectedOrgId;

        this.http.post(`${this.orgUrl}`, this.selectedOrgId)
            .subscribe(
                data => { this.getValidUrl(); }
            )
    }

    getValidUrl() {

        let parentUrl = location.href;
        let originUrl = parentUrl.replace(location.origin, '');

        this.http.get(`${this.pageValidityUrl}/${originUrl.replace(/\//g, "@").replace("#", "$")}`)
            .subscribe(
                data => {
                    if (data == '')
                        window.location.reload();
                    //Added below to resolve checkmarx issues
                    else if (data == '/Login/Main')
                        location.replace('/Login/Main');
                    else if (data == '/Login/Default')
                        location.replace('/Login/Default');
                }
            )
    }

    signOut() {
        localStorage.clear(); 
        location.replace('/Login');
    }
    skipnavigation() {
        let ele: any = document.querySelector('[role="main"]');
        ele.setAttribute('tabIndex', '-1');
        setTimeout(() => {
            ele.focus();
            ele.removeAttribute('tabIndex');
        }, 0);
    }

    changeOfRoutes() {
        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) { /* Your code goes here on every router change */ }
            this.highLightHeader();
        });
    }

    highLightHeader() {
        try {
            var locationvalue = location.hash;
            var pagename = locationvalue.split('/');
            var element: any = document.querySelector("#menu a[href*=" + pagename[1] + "]");
            var activeelement = <HTMLElement>document.querySelector('li.active');
            if (activeelement)
                activeelement.classList.remove('active');
            var timeout;
            if (element && this.getClosest(element, 'li')) {
                this.getClosest(element, 'li').classList.add("active");
                var closestUL = this.getClosest(element, 'ul');
                if (this.getClosest(closestUL, "li"))
                    this.getClosest(closestUL, "li").classList.add("active");
                clearTimeout(timeout);
            }
            else
                timeout = setTimeout(() => { this.highLightHeader(); }, 3000);
        }
        catch (exception) {
            console.exception(exception);
        }
    }

    getClosest(el, tag) {
        tag = tag.toUpperCase();
        do {
            if (el.nodeName === tag) {
                return el;
            }
        } while (el = el.parentNode);
        return null;
    }
}
