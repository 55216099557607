import { Injectable } from '@angular/core'  
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs';
import { AuthService } from '../../user/auth.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        request = request.clone({
            setHeaders: {
                Authorization: `${this.authService.getJwtToken()}` 
            },
            withCredentials: true
        });
        return next.handle(request);
    }
}