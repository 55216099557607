import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class LogResponseInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req)
      .pipe(
        tap(event => {
          if (event.type === HttpEventType.Response) {
            //console.log(event.body);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          debugger;
          if (error.status === 401) {
            localStorage.setItem('isUserLoggedIn', 'false');
            location.replace('/#/Login');

          }
          return throwError(error);
        })
      );
  }
}
