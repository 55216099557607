import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { CoreService } from './core/core.service';
//import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HttpRequestInterceptor } from './core/interceptors/http-request-interceptor';
import { ErrorComponent } from './error/component/error.component';
import { GetErrorMessagePipe } from './shared/pipes/get-error-message.pipe';

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    GetErrorMessagePipe
    //PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    CoreModule,
    AppRoutingModule 
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    CoreService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
