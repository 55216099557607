import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getErrorMessage'
})
export class GetErrorMessagePipe implements PipeTransform {

  transform(control: string, ...args: string[]): string {
    console.log('In pipe: ', control, args);
    const el = document.getElementById(control);
    if (el && args && args[0] == 'invalid' && args.length == 2) {
      return args[1];
    } else if (el && args && args[0]) {
      return el.dataset['val' + this.capitalizeFirstLetter(args[0])]
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
