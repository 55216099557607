import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Organizations } from './view-models/organizations';
import { CustomError } from './view-models/custom-error';
import { LogModel } from './view-models/log-model';

@Injectable()
export class CoreService {
    private marginalsUrl = '/api/layout/marginals';
    private menuUrl = '/api/layout/menu';
    private orgsUrl = '/api/layout/organizations';
    private logInfoUrl = '/api/logger/Info';
    private logErrorUrl = '/api/logger/Error';
    private cvlogInfoUrl = '/api/cvlogger/Info';
    private cvlogErrorUrl = '/api/cvlogger/Error';

    loading: boolean = false;
    headers = new HttpHeaders();
    httpParams = new HttpParams();
    constructor(private http: HttpClient) { }

    getMarginals(): Observable<any> {
        return this.http.get<any>(`${this.marginalsUrl}`)
            .pipe(
                map(response => this.extractData(response)),
                catchError(this.handleError)
            )
    }

    getMenu(): Observable<string | CustomError> {
        return this.http.get<string>(`${this.menuUrl}`)
            .pipe(
                map(response => response),
                catchError(err => this.handleError(err))
            )
    }

    getOrganizations(): Observable<Organizations> {
        return this.http.get<Organizations>(`${this.orgsUrl}`)
            .pipe(
                map(response => this.extractData(response)),
                catchError(this.handleError)
            )
    }

    logInfo(message: string): Observable<any> {
        var logModel: LogModel = new LogModel();
        logModel.message = message;
        return this.http.post(`${this.logInfoUrl}`, logModel, { headers: this.headers })
            .pipe(
                catchError(this.handleError)
            )
    }

    logError(message: string): Observable<any> {
        var logModel: LogModel = new LogModel();
        logModel.message = message;
        return this.http.post(`${this.logErrorUrl}`, logModel, { headers: this.headers })
            .pipe(
                catchError(this.handleError)
            )
    }


    isLoading() {
        return this.loading;
    }

    loadingStart() {
        this.loading = true;
        document.getElementById('container').style.opacity = '0.5';
    }

    loadingComplete() {
        this.loading = false;
        document.getElementById('container').style.opacity = '1';
    }

    private extractData(res: any) {
        return res;
    }

    public handleError(error: any): Observable<CustomError> {

        // Rename handleError to handleHttpError
        let err = new CustomError();

        if (error.error instanceof ErrorEvent)
            err.errorMessage = `An error occured: ${error.error == undefined ? error.message : error.error.message}`;
        else {
            err.customMessage = error.error != null ? error.error : '';
            err.errorMessage = `Server Error: $ Http_Status: ${error.status},  Message: ${error.body == undefined ? error.message : error.body.error}`;
        }

        this.logCVError(err.errorMessage)
            .subscribe(
                (data: any) => { return data }
            );
        return throwError(err);
    }

    /*Cost Vantage Loggin Start*/
    logCVInfo(message: string): Observable<any> {
        var logModel: LogModel = new LogModel();
        logModel.message = message;
        return this.http.post(`${this.cvlogInfoUrl}`, logModel, { headers: this.headers })
            .pipe(
                catchError(this.handleError)
            )
    }

    logCVError(message: string): Observable<any> {
        var logModel: LogModel = new LogModel();
        logModel.message = message;
        return this.http.post(`${this.cvlogErrorUrl}`, logModel, { headers: this.headers })
            .pipe(
                catchError(this.handleError)
            )
    }

    public handleCVError(error: any): Observable<CustomError> {

        // Rename handleError to handleHttpError
        let err = new CustomError();

        if (error.error instanceof ErrorEvent)
            err.errorMessage = `An error occured: ${error.error == undefined ? error.message : error.error.message}`;
        else {
            err.customMessage = error.error != null ? error.error : '';
            err.errorMessage = `Server Error: $ Http_Status: ${error.status},  Message: ${error.body == undefined ? error.message : error.body.error}`;
        }

        this.logCVError(err.errorMessage)
            .subscribe(
                (data: any) => { return data }
            );
        return throwError(err);
    }
    /*Cost Vantage Loggin End*/
}
