import { Component, OnInit } from '@angular/core';
import { CoreService } from '../core.service';
import { CustomError } from '../view-models/custom-error';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  menuText: string;
  errorMessage: string;

  constructor(private coreService: CoreService) { }

  ngOnInit() {
   this.coreService.getMenu()
        .subscribe(
          (menu: string) => this.menuText = menu,
          (error : CustomError) => {
            this.errorMessage = <any>error;
            location.href = '/';
        });
  }

}
