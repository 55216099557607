import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutComponent } from './layout/layout.component';
import { MenuComponent } from './menu/menu.component';
import { LoggerService } from './logger.service';
import { ErrorHandlerService } from './error-handler.service';
import { PageHeaderComponent } from './page-header/page-header.component';
import { LogResponseInterceptor } from './interceptors/log-response.interceptor';
import { UserModule } from '../user/user.module';
import { AuthService } from '../user/auth.service';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        LayoutComponent,
        MenuComponent,
        PageHeaderComponent
    ],
    providers: [
        LoggerService,
        AuthService,
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: HTTP_INTERCEPTORS, useClass: LogResponseInterceptor, multi: true },

    ],
    exports: [
        LayoutComponent,
        MenuComponent,
        PageHeaderComponent
    ]
})
export class CoreModule { }
