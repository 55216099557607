import { Injectable } from '@angular/core';
import { CoreService } from './core.service';

@Injectable()
export class LoggerService {

    logTime: string = new Date().toLocaleTimeString();

    constructor(private coreService: CoreService) { }

    //Instead of msg, define a view-mode for logEntry with properties EntryType, Message etc...

    log(msg: string) {
        var infoMessage = `Logged Message at : ${this.logTime}, Description: ${msg}`;
        this.coreService.logInfo(infoMessage)
            .subscribe(
                (data: any) => { return data }
            );
        //Call Logger API to log Client info
    }

    error(msg: string) {
        var infoMessage = `Logged Error at : ${this.logTime}, Description: ${msg}`;
        this.coreService.logError(infoMessage)
            .subscribe(
                (data: any) => { return data }
            );
        //Call Logger API to log Client error
    }
}
