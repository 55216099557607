import { ErrorDetail } from './error-detail';

export class ErrorContent {
    heading1: string;
    heading2: string;
    errorDetails: ErrorDetail[];

    constructor(heading1: string, heading2: string) {
        this.heading1 = heading1;
        this.heading2 = heading2;
        this.errorDetails = [];
    }
}
