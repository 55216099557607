import { Injectable, ErrorHandler } from '@angular/core';
import { CustomError } from './view-models/custom-error';
import { CoreService } from './core.service';
import { Router } from '@angular/router';


@Injectable()
export class ErrorHandlerService implements ErrorHandler {

    constructor(private router: Router, private coreService: CoreService) { }
    handleError(error: any): void {
        if (error.statusText === 'Unauthorized') { 
                localStorage.clear();
                this.router.navigate(['/Login']); 
        }

        //LogError
        let err: CustomError = new CustomError();
        err.number = 9999;
        err.errorMessage = (<Error>error).message;
        err.customMessage = "Error Occurred...Please contact Customer Support"; 
            this.coreService.logCVError(err.errorMessage)
                .subscribe(
                    (data: any) => { return data }
                ); 
        // Call Logger Service.LogError method to to call  Logger API to log Error
        // To test from anywhere in app try following statement
        // throw new Error('Testing Some Error');
    }
}
